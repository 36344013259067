import { useStore } from "@/store";

class NavigationServiceClass {
  navigate(path: string) {
    const currentUrlParams = new URLSearchParams(window.location.search);

    const urlString = `${path}${currentUrlParams.toString() ? `?${currentUrlParams.toString()}` : ""}`;

    console.log("urlString", urlString);
    useStore.getState().navigation.setNavToPath(urlString);
  }

  openExternalLink(url: string) {
    window.open(url, "_blank");
  }

  navToLogin() {
    this.navigate("/login");
  }
}

export const Navigation = new NavigationServiceClass();
