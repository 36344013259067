import { IAdvizerModel } from "@/models/Advizer";
import { IQuote } from "@/types/ApiTypes";
import { Group, lighten } from "@mantine/core";
import { Text } from "./ui/Text";
import { useStyles } from "@/styles/useStyles";

export const AdvizerQuotes = (props: {
  advizer?: IAdvizerModel;
  activeQuoteIndex?: number;
  activeQuote?: IQuote;
}) => {
  const { advizer, activeQuote } = props;
  const { theme } = useStyles();

  if (!advizer || !activeQuote?.snippet) {
    return null;
  }

  return (
    <Group
      p="sm"
      bg={lighten(theme.colors?.background[1], 0.5)}
      style={{
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: `3px solid ${theme.colors?.primary[6]}`,
        fontStyle: "italic",
      }}
    >
      <Text
        c="text.9"
        fw={500}
        lineClamp={3}
        ta="left"
        style={{ letterSpacing: 0.3 }}
      >
        "{activeQuote?.snippet}"
      </Text>
    </Group>
  );
};
