import { forwardRef, useCallback } from "react";
import {
  Button as MantineButton,
  ButtonProps as MantineButtonProps,
} from "@mantine/core";
import { Link } from "@/components/ui/Link";

export interface ButtonProps
  extends MantineButtonProps,
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color" | "style"> {
  onClick?: (event: any) => void;
  to?: string;
  ariaLabel?: string;
  target?: string;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { className, onClick, to, ariaLabel, target, ...rest } = props;

    const childrenString =
      typeof props.children === "string" ? props.children : "";

    const renderButton = useCallback(
      (extraProps?: any) => {
        return (
          <MantineButton
            className={`custom-button ${className}`}
            aria-label={ariaLabel || childrenString}
            color="primary"
            {...rest}
            {...extraProps}
            ref={ref}
            onClick={onClick}
          />
        );
      },
      [ariaLabel, childrenString, className, onClick, ref, rest],
    );

    if (to) {
      return (
        <Link to={to} aria-label={ariaLabel || childrenString} target={target}>
          {renderButton()}
        </Link>
      );
    }
    return renderButton({ color: "primary" });
  },
);

Button.displayName = "Button";
