import { create, StateCreator } from "zustand";
import { produce } from "immer";
import {
  IAdvizeApiDataKeys,
  IAdvizerSearchFilterKeys,
  IStoreState,
} from "../types/StoreTypes";
import { IAdvizerModel } from "../models/Advizer";
import { User as FirebaseUser } from "firebase/auth";

const STORAGE_KEY = "advize-local-storage";

const defaultLoclStorage = {
  partner: {},
  user: {},
};

const loadFromStorage = () => {
  const stored = localStorage.getItem(STORAGE_KEY);
  return stored ? JSON.parse(stored) : defaultLoclStorage;
};

type UpdateConfig = {
  saveLocal?: boolean;
  convertFunc?: (val: any) => any;
};

const createStore: StateCreator<IStoreState> = (set) => {
  const localStorageState = loadFromStorage() || {};

  // console.log("localStorageState", localStorageState);

  const setAndProduce = (
    fn: (state: IStoreState) => void,
    config: UpdateConfig = {},
  ) => {
    return set(
      produce((state) => {
        fn(state);

        if (config?.saveLocal) {
          const localStorageStateNow = loadFromStorage() || {};

          (
            Object.keys(defaultLoclStorage) as Array<
              keyof typeof defaultLoclStorage
            >
          ).forEach((key) => {
            if (!localStorageStateNow[key]) {
              localStorageStateNow[key] = defaultLoclStorage[key];
            }
          });

          const updatedLocalState = produce(localStorageStateNow, fn);
          localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedLocalState));
        }
      }),
    );
  };

  return {
    isAppLoading: true,
    setIsAppLoading: (isLoading: boolean) =>
      setAndProduce((state) => {
        state.isAppLoading = isLoading;
      }),

    partner: {
      activePartner: localStorageState?.partner?.activePartner || null,
      setActivePartner: (partner: any | null) =>
        setAndProduce(
          (state) => {
            if (typeof partner !== "undefined") {
              state.partner.activePartner = partner;
            }
          },
          { saveLocal: true, convertFunc: (val) => val.toJSON() },
        ),
    },

    user: {
      activeUser: localStorageState?.user?.activeUser || null,
      numVideosPlayed: localStorageState?.user?.numVideosPlayed || 0,
      showUserInterviewForm: false,
      setShowUserInterviewForm: (show: boolean) =>
        setAndProduce((state) => {
          state.user.showUserInterviewForm = show;
        }),
      setUser: (user: FirebaseUser) => {
        setAndProduce(
          (state) => {
            state.user.activeUser = user?.toJSON() || null;
          },
          { saveLocal: true, convertFunc: (val) => val.toJSON() },
        );
      },
      updateActiveUser: (data: any) =>
        setAndProduce(
          (state) => {
            state.user.activeUser = { ...state.user.activeUser, ...data };
          },
          { saveLocal: true, convertFunc: (val) => val.toJSON() },
        ),
      incrementNumVideosPlayed: () =>
        setAndProduce(
          (state) => {
            if (state.user.activeUser) return;
            state.user.numVideosPlayed = (state.user.numVideosPlayed || 0) + 1;
          },
          { saveLocal: true },
        ),
      clearNumVideosPlayed: () =>
        setAndProduce(
          (state) => {
            state.user.numVideosPlayed = 0;
          },
          { saveLocal: true },
        ),
    },

    navigation: {
      navToPath: null,
      setNavToPath: (path: string | null) =>
        setAndProduce((state) => {
          state.navigation.navToPath = path;
        }),
    },

    advizerSearch: {
      activeFilterPartnerId: null,
      setActiveFilterPartnerId: (partnerId: string | null) =>
        setAndProduce((state) => {
          state.advizerSearch.activeFilterPartnerId = partnerId;
        }),
      searchText: "",
      setSearchText: (searchText?: string) =>
        setAndProduce((state) => {
          state.advizerSearch.searchText = searchText || "";
        }),
      activeQuestionId: null,
      setActiveQuestionId: (questionId: string | null) =>
        setAndProduce((state) => {
          state.advizerSearch.activeQuestionId = questionId || null;
        }),
      isInlineAdvizerSearchOpen: false,
      setIsInlineAdvizerSearchOpen: (isOpen: boolean) =>
        setAndProduce((state) => {
          state.advizerSearch.isInlineAdvizerSearchOpen = isOpen;
        }),
      isSearchingAdvizers: false,
      setIsSearchingAdvizers: (isSearching: boolean) =>
        setAndProduce((state) => {
          state.advizerSearch.isSearchingAdvizers = isSearching;
        }),
      subjects: {},
      industries: {},
      jobFunctions: {},
      traits: {},
      companies: {},
      toggleFilterOption: (key: IAdvizerSearchFilterKeys, value: string) =>
        setAndProduce((state) => {
          if (state.advizerSearch[key][value]) {
            delete state.advizerSearch[key][value];
          } else {
            state.advizerSearch[key][value] = true;
          }
        }),
      resetAdvizerSearch: () =>
        setAndProduce((state) => {
          state.advizerSearch.searchText = "";
          state.advizerSearch.isSearchingAdvizers = false;
          state.advizerSearch.subjects = {};
          state.advizerSearch.industries = {};
          state.advizerSearch.jobFunctions = {};
          state.advizerSearch.traits = {};
          state.advizerSearch.companies = {};
          state.advizerSearch.activeQuestionId = null;
        }),
      collapseHeader: false,
      setCollapseHeader: (collapseHeader: boolean) =>
        setAndProduce((state) => {
          state.advizerSearch.collapseHeader = collapseHeader;
        }),
    },

    videos: {
      activeVideoId: null,
      setActiveVideoId: (videoId: string | null) =>
        setAndProduce((state) => {
          state.videos.activeVideoId = videoId;
        }),
    },

    drawers: {
      activeDrawerId: null,
      setActiveDrawer: (drawerId: string | null) =>
        setAndProduce((state) => {
          state.drawers.activeDrawerId = drawerId;
        }),
      closeActiveDrawer: () =>
        setAndProduce((state) => {
          state.drawers.activeDrawerId = null;
        }),
    },

    dialog: {
      activeDialogId: null,
      setActiveDialog: (dialogId: string | null) =>
        setAndProduce((state) => {
          state.dialog.activeDialogId = dialogId;
        }),
    },

    advizeApiData: {
      advizers: null,
      subjects: null,
      jobFunctions: null,
      traits: null,
      industries: null,
      companies: null,
      questions: null,
      partners: null,
      quotes: null,
      descriptors: null,
      setApiData: (params: { key: IAdvizeApiDataKeys; data: any[] }) => {
        setAndProduce((state) => {
          state.advizeApiData[params.key] = params.data;
        });
      },
      addAdvizers: (advizers: IAdvizerModel[]) =>
        setAndProduce((state) => {
          state.advizeApiData.advizers = [
            ...(state.advizeApiData.advizers || []),
            ...advizers,
          ];
        }),
    },

    advizerInfoById: {},
    setAdvizerInfoById: (advizerId: string, info: any) =>
      setAndProduce((state) => {
        state.advizerInfoById[advizerId] = info;
      }),

    experience: {
      showConfetti: false,
      setShowConfetti: (showConfetti: boolean) =>
        setAndProduce((state) => {
          state.experience.showConfetti = showConfetti;
        }),
    },
  };
};

export const useStore = create(createStore);
