import { Container, Group } from "@mantine/core";
import { Title } from "@/components/ui/Title";
import { Text } from "@/components/ui/Text";
import { Button } from "@/components/ui/Button";
import { useNavigate } from "react-router-dom";
import { Analytics } from "@/services/Analytics";
import { useEffect } from "react";

export const Page404 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    Analytics.pageNotFound();
  }, []);

  return (
    <Container py={80}>
      <Title order={1} ta="center" mb={16}>
        Whoops! Page not found.
      </Title>
      <Text c="dimmed" size="lg" ta="center" mb={32}>
        The page you are looking for doesn't exist or has been moved to another
        URL
      </Text>
      <Group justify="center">
        <Button onClick={() => navigate("/")}>Take me back home</Button>
      </Group>
    </Container>
  );
};
