import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { IAdvizerTagTypes } from "@/components/AdvizerTag";
import { ResourceConfigs } from "@/configs/resourceConfigs";
import { AdvizerVideo } from "@/models/AdvizerVideo";
import { IAdvizer, IVideo } from "@/types/ApiTypes";
import { capitalizeEveryWord } from "@/lib/helpers";

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo("en-US");

export class Advizer implements IAdvizer {
  declare id: IAdvizer["id"];
  declare name: IAdvizer["name"];
  declare email: IAdvizer["email"];
  declare companyRaw: IAdvizer["companyRaw"];
  declare publicCompanyName: IAdvizer["publicCompanyName"];
  declare jobTitle: IAdvizer["jobTitle"];
  declare profilePictureURL: IAdvizer["profilePictureURL"];
  declare interviews: IAdvizer["interviews"];
  declare partners: IAdvizer["partners"];
  declare subjects: IAdvizer["subjects"];
  declare traits: IAdvizer["traits"];
  declare industries: IAdvizer["industries"];
  declare jobFunctions: IAdvizer["jobFunctions"];
  declare bio: IAdvizer["bio"];
  declare undergradSchool: IAdvizer["undergradSchool"];
  declare gradProgramsRaw: IAdvizer["gradProgramsRaw"];
  declare formerCompaniesRaw: IAdvizer["formerCompaniesRaw"];
  declare identityVisibility: IAdvizer["identityVisibility"];
  declare personalBrandingLinks: IAdvizer["personalBrandingLinks"];
  declare quotes: IAdvizer["quotes"];
  declare createdAt: IAdvizer["createdAt"];
  declare updatedAt: IAdvizer["updatedAt"];

  defaultQuestionNumber: number;

  constructor(apiData: IAdvizer) {
    const { ...rest } = apiData;
    Object.assign(this, rest);

    this.name = capitalizeEveryWord(apiData.name);
    this.publicCompanyName = capitalizeEveryWord(
      apiData.publicCompanyName || "",
    );
    this.jobTitle = capitalizeEveryWord(apiData.jobTitle || "");

    this.defaultQuestionNumber = Math.floor(
      Math.random() * (apiData.interviews?.[0]?.videos?.length || 0),
    );

    // this.id = apiData.id;
    // this.name = apiData.name;
    // this.companyRaw = apiData.companyRaw;
    // this.publicCompanyName = apiData.publicCompanyName || "";
    // this.jobTitle = apiData.jobTitle;
    // this.profilePictureURL = apiData.profilePictureURL;
    // this.interviews = apiData.interviews;
    // this.partners = apiData.partners;
    // this.subjects = apiData.subjects;
    // this.traits = apiData.traits;
    // this.industries = apiData.industries;
    // this.jobFunctions = apiData.jobFunctions;
    // this.bio = apiData.bio;
    // this.undergradSchool = apiData.undergradSchool;
    // this.gradProgramsRaw = apiData.gradProgramsRaw;
    // this.identityVisibility =
    //   apiData.identityVisibility || apiData.identifyVisibility;
    // this.personalBrandingLinks = apiData.personalBrandingLinks;
  }

  get firstName() {
    const nameParts = this.name.split(" ");
    const prefixes = ["Dr.", "Mr.", "Mrs.", "Ms.", "Prof."];
    if (prefixes.includes(nameParts[0])) {
      return `${nameParts[0]} ${nameParts[1]}`;
    }
    return nameParts[0];
  }

  get interviewedAt() {
    return this.interviews?.[0]?.interviewedAt;
  }

  get interviewedTimeAgo() {
    let interviewedAt = this.interviewedAt;
    if (!interviewedAt) return null;

    if (interviewedAt.toString().startsWith("00")) {
      // flip the 002* to 202*
      interviewedAt = interviewedAt.replace("00", "20");
    }

    const time = timeAgo.format(new Date(interviewedAt));

    return time;
  }

  get company() {
    return this.publicCompanyName || "";
  }

  get interview() {
    return this.interviews?.[0] || { videos: [] };
  }

  get videos() {
    return (this.interview?.videos || [])
      .filter((v: IVideo) => {
        return v.url.includes("vimeo");
      })
      .map((video: IVideo) => {
        return new AdvizerVideo({ ...video, advizer: this });
      })
      .sort((a: AdvizerVideo, b: AdvizerVideo) => {
        return (a.questionNumber || 10000) - (b.questionNumber || 10000);
      });
  }

  get tags() {
    return [
      ...(this.subjects || []).map((subject) => ({
        id: subject.id,
        title: subject.title,
        type: ResourceConfigs.subject.key as IAdvizerTagTypes,
      })),
      ...(this.traits || [])
        .filter((trait) => trait.title !== "None Applicable")
        .map((trait) => ({
          id: trait.id,
          title: trait.title,
          type: ResourceConfigs.trait.key as IAdvizerTagTypes,
        })),
      ...(this.industries || []).map((industry) => ({
        id: industry.id,
        title: industry.title,
        type: ResourceConfigs.industry.key as IAdvizerTagTypes,
      })),
      ...(this.jobFunctions || []).map((jobFunction) => ({
        id: jobFunction.id,
        title: jobFunction.title,
        type: ResourceConfigs.jobFunction.key as IAdvizerTagTypes,
      })),
    ].sort(
      (a, b) => ResourceConfigs[a.type].order - ResourceConfigs[b.type].order,
    );
  }

  get subjectTags() {
    return this.tags.filter((tag) => tag.type === ResourceConfigs.subject.key);
  }

  get traitTags() {
    return this.tags.filter((tag) => tag.type === ResourceConfigs.trait.key);
  }

  get industryTags() {
    return this.tags.filter((tag) => tag.type === ResourceConfigs.industry.key);
  }

  get jobFunctionTags() {
    return this.tags.filter(
      (tag) => tag.type === ResourceConfigs.jobFunction.key,
    );
  }

  get searchText() {
    return `${this.firstName} ${this.company} ${this.jobTitle} ${(this.subjects || []).map((s) => s.title).join(" ")} ${(this.traits || []).map((t) => t.title).join(" ")} ${(this.industries || []).map((i) => i.title).join(" ")} ${(this.jobFunctions || []).map((j) => j.title).join(" ")} ${this.bio} ${(this.partners || []).map((p) => p.title).join(" ")} ${this.formerCompaniesRaw} ${this.undergradSchool} ${this.gradProgramsRaw}`.toLowerCase();
  }

  checkHasQuestion(questionId: string) {
    return this.videos.some((video) => video.questionId === questionId);
  }
}

export type IAdvizerModel = Advizer;
