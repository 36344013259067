import {
  createTheme,
  MantineColorShade,
  MantineColorsTuple,
  DEFAULT_THEME,
} from "@mantine/core";
import { useActiveUserPartner } from "../store/selectors";
import {
  advizeBlue,
  advizeGreen,
  allianceGreen,
  allianceOrange,
  asuGold,
  asuMaroon,
  berkeleyPrimary,
  berkeleySecondary,
  californiaLutheranPrimary,
  californiaLutheranSecondary,
  compassDiversifiedPrimary,
  compassDiversifiedSecondary,
  hericansPrimary,
  hericansSecondary,
  knaussPrimary,
  knaussSecondary,
  mindsMatterPrimary,
  mindsMatterSecondary,
  sacStateGold,
  sacStateGreen,
  slate,
  uclaAndersonPrimary,
  uclaAndersonSecondary,
  stanfordPrimary,
  stanfordSecondary,
  stanfordAccent1,
  stanfordAccent2,
  stanfordAccent3,
  stanfordAccent4,
  stanfordAccent5,
  uscaSecondary,
  uscaPrimary,
  arizonaEllerPrimary,
  arizonaEllerSecondary,
  purduePrimary,
  purdueSecondary,
  uvaDardenPrimary,
  uvaDardenSecondary,
  sierraCollegePrimary,
  sierraCollegeSecondary,
  jessupUniversityPrimary,
  jessupUniversitySecondary,
} from "./colors";

type IThemeOverride = {
  primaryShade: MantineColorShade;
  colors: {
    primary: MantineColorsTuple;
    secondary: MantineColorsTuple;
    background: MantineColorsTuple;
    border: MantineColorsTuple;
    text: MantineColorsTuple;
    loginBackground?: MantineColorsTuple;
    accentColor1?: MantineColorsTuple;
    accentColor2?: MantineColorsTuple;
    accentColor3?: MantineColorsTuple;
    accentColor4?: MantineColorsTuple;
    accentColor5?: MantineColorsTuple;
  };
};

const advizeTheme: IThemeOverride = {
  primaryShade: 7,
  colors: {
    primary: advizeBlue,
    secondary: advizeGreen,
    background: slate,
    border: slate,
    text: slate,
  },
};

const schoolThemes: Record<string, IThemeOverride> = {
  ["sacramento-state"]: {
    primaryShade: 8,
    colors: {
      primary: sacStateGreen,
      secondary: sacStateGold,
      background: slate,
      border: sacStateGold,
      text: slate,
    },
  },
  ["asu-w.p.-carey-school-of-business"]: {
    primaryShade: 9,
    colors: {
      primary: asuMaroon,
      secondary: asuGold,
      background: slate,
      border: asuGold,
      text: slate,
    },
  },
  ["alliance-college-ready-public-schools"]: {
    primaryShade: 7,
    colors: {
      primary: allianceGreen,
      secondary: allianceOrange,
      background: slate,
      border: slate,
      text: slate,
    },
  },
  ["california-lutheran-university"]: {
    primaryShade: 7,
    colors: {
      primary: californiaLutheranPrimary,
      secondary: californiaLutheranSecondary,
      background: slate,
      border: slate,
      text: slate,
    },
  },
  ["compass-diversified"]: {
    primaryShade: 7,
    colors: {
      primary: compassDiversifiedPrimary,
      secondary: compassDiversifiedSecondary,
      background: slate,
      border: compassDiversifiedSecondary,
      text: slate,
    },
  },
  ["hericanes"]: {
    primaryShade: 6,
    colors: {
      primary: hericansPrimary,
      secondary: hericansSecondary,
      background: slate,
      border: hericansPrimary,
      text: slate,
    },
  },
  ["minds-matter"]: {
    primaryShade: 6,
    colors: {
      primary: mindsMatterPrimary,
      secondary: mindsMatterSecondary,
      background: slate,
      border: slate,
      text: slate,
    },
  },
  ["uc-berkeley---social-sciences"]: {
    primaryShade: 8,
    colors: {
      primary: berkeleyPrimary,
      secondary: berkeleySecondary,
      background: slate,
      border: slate,
      text: slate,
    },
  },
  ["ucla-anderson"]: {
    primaryShade: 9,
    colors: {
      primary: uclaAndersonPrimary,
      secondary: uclaAndersonSecondary,
      background: slate,
      border: slate,
      text: slate,
    },
  },
  ["usd-knauss-school-of-business"]: {
    primaryShade: 6,
    colors: {
      primary: knaussPrimary,
      secondary: knaussSecondary,
      background: slate,
      border: slate,
      text: slate,
    },
  },
  ["stanford-eng-comp-forum"]: {
    primaryShade: 8,
    colors: {
      primary: stanfordPrimary,
      secondary: stanfordSecondary,
      background: slate,
      border: slate,
      text: slate,
      accentColor1: stanfordAccent1,
      accentColor2: stanfordAccent2,
      accentColor3: stanfordAccent3,
      accentColor4: stanfordAccent4,
      accentColor5: stanfordAccent5,
    },
  },
  ["usca"]: {
    primaryShade: 7,
    colors: {
      primary: uscaPrimary,
      secondary: uscaSecondary,
      background: slate,
      border: slate,
      text: slate,
    },
  },
  ["university-of-arizona---eller-college-of-management"]: {
    primaryShade: 7,
    colors: {
      primary: arizonaEllerPrimary,
      secondary: arizonaEllerSecondary,
      background: slate,
      border: slate,
      text: slate,
    },
  },
  ["purdue-university-mitch-daniels-school-of-business"]: {
    primaryShade: 7,
    colors: {
      primary: purduePrimary,
      secondary: purdueSecondary,
      background: slate,
      border: slate,
      text: slate,
    },
  },
  ["uva-darden-school-of-business"]: {
    primaryShade: 8,
    colors: {
      primary: uvaDardenPrimary,
      secondary: uvaDardenSecondary,
      background: slate,
      border: slate,
      text: slate,
    },
  },
  ["sierra-college"]: {
    primaryShade: 7,
    colors: {
      primary: sierraCollegePrimary,
      secondary: sierraCollegeSecondary,
      background: slate,
      border: slate,
      text: slate,
    },
  },
  ["jessup-university"]: {
    primaryShade: 7,
    colors: {
      primary: jessupUniversityPrimary,
      secondary: jessupUniversitySecondary,
      background: slate,
      border: slate,
      text: slate,
    },
  },
};

export const useTheme = () => {
  const partner = useActiveUserPartner();

  const themeToUse =
    schoolThemes[partner?.slug as keyof typeof schoolThemes] || advizeTheme;

  const { colors, ...rest } = themeToUse;

  return createTheme({
    fontFamily: "Arial,Helvetica,sans-serif",
    primaryColor: "blue",
    headings: {
      fontFamily: "League Spartan, sans-serif",
    },
    breakpoints: {
      xs: "36em",
      sm: "48em",
      md: "62em",
      lg: "75em",
      xl: "88em",
      mobileMax: "550px",
      tabletMax: "1100px",
      laptopMax: "1400px",
    },
    colors: {
      slate,
      accentColor1: DEFAULT_THEME.colors.pink, // Use Mantine's pink color tuple
      accentColor2: DEFAULT_THEME.colors.cyan,
      accentColor3: DEFAULT_THEME.colors.grape,
      accentColor4: DEFAULT_THEME.colors.orange,
      accentColor5: DEFAULT_THEME.colors.green,
      ...colors,
    },
    ...rest,
  });
};
