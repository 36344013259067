import { forwardRef, useCallback, useState } from "react";
import {
  Group,
  NavLinkProps as MantineNavLinkProps,
  MantineSize,
  Text,
  TextProps,
} from "@mantine/core";
import { Link, LinkProps } from "@/components/ui/Link";
import { Button } from "@/components/ui/Button";
import { useStyles } from "@/styles/useStyles";
import { Icon123 } from "@tabler/icons-react";
import { useLocation } from "react-router-dom";

export interface NavLinkProps extends MantineNavLinkProps {
  onClick?: (event: any) => void;
  to?: string | ((params: any) => string);
  linkProps?: Omit<LinkProps, "to">;
  Icon?: typeof Icon123;
  CustomIcon?: React.ReactNode;
  size?: MantineSize;
  textProps?: TextProps;
  rightSection?: React.ReactNode;
  ariaLabel?: string;
}

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (props, _ref) => {
    const {
      className,
      linkProps,
      Icon,
      CustomIcon,
      children,
      size = "md",
      textProps,
      onClick,
      rightSection,
      ariaLabel,
    } = props;
    let to = props.to;
    const { theme } = useStyles();
    const { pathname } = useLocation();
    const isActive = pathname === to;
    const [, setIsHovered] = useState(false);

    const childrenString = typeof children === "string" ? children : "";

    if (typeof to === "function") {
      to = to(props);
    }

    const renderNavLink = useCallback(() => {
      return (
        <Button
          role="link"
          aria-label={ariaLabel || childrenString}
          aria-current={isActive ? "page" : undefined}
          unstyled
          fullWidth
          w="100%"
          onClick={onClick}
          pt={16}
          pb={16}
          px="xs"
          size={size}
          className={`adv-nav-link underline-on-hover ${className || ""}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          radius="md"
        >
          <Group justify="space-between" align="center" wrap="nowrap">
            <Group wrap="nowrap">
              {CustomIcon ||
                (Icon && <Icon size={24} color={theme.colors.gray[8]} />)}
              <Text
                size={size}
                ta="left"
                {...textProps}
                fw={isActive ? 700 : 500}
                lineClamp={1}
                title={typeof children === "string" ? children : ""}
              >
                {typeof children === "string"
                  ? children.length > 100
                    ? `${children.slice(0, 100)}...`
                    : children
                  : children}
              </Text>
            </Group>
            {rightSection}
          </Group>
        </Button>
      );
    }, [
      ariaLabel,
      childrenString,
      isActive,
      onClick,
      size,
      className,
      CustomIcon,
      Icon,
      theme.colors.gray,
      textProps,
      children,
      rightSection,
    ]);

    if (to) {
      return (
        <Link to={to} {...linkProps}>
          {renderNavLink()}
        </Link>
      );
    }

    return renderNavLink();
  },
);

NavLink.displayName = "NavLink";
