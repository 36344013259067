import {
  AspectRatio,
  Box,
  Card,
  Flex,
  Group,
  Loader,
  Space,
  Spoiler,
  Stack,
} from "@mantine/core";
import logoLight from "../assets/advize-logo-light.jpg";
import {
  IconBulb,
  IconChevronLeft,
  IconChevronRight,
  IconUser,
  IconVideo,
} from "@tabler/icons-react";
import { AdvizerVideo } from "@/components/AdvizerVideo";
import { Link, useParams } from "react-router-dom";
import { PartnerLogo } from "@/components/PartnerLogo";
import { AdvizerSearchControls } from "@/components/advizerSearchControls/AdvizerSearchControls";
import { useIsLaptopOrBigger, useIsTabletOrBigger } from "@/styles/useStyles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AdvizerSearchDrawer } from "@/components/drawers/AdvizerSearchDrawer";
import { Button } from "@/components/ui/Button";
import { AdvizerVideoSlideshow } from "@/components/AdvizerVideoSlideshow";
import {
  useAdvizer,
  useAdvizerQuotes,
  useFilteredAdvizers,
} from "@/store/selectors";
import { AdvizerTag } from "@/components/AdvizerTag";
import { parsePersonalBrandingLinks } from "@/lib/helpers";
import { IAdvizerVideo } from "@/models/AdvizerVideo";
import { useStore } from "@/store";
import { IAdvizerModel } from "@/models/Advizer";
import { Image } from "@/components/ui/Image";
import { Text } from "@/components/ui/Text";
import { Title } from "@/components/ui/Title";
import { Navigation } from "@/services/Navigation";
import { IQuote } from "@/types/ApiTypes";
import { AdvizerQuotes } from "@/components/AdvizerQuotes";

export const AdvizerProfile = () => {
  const { advizerId, tab } = useParams();
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const isTabletOrBigger = useIsTabletOrBigger();

  const advizer = useAdvizer(advizerId || "");
  const [currentSlideIndex, setCurrentSlideIndex] = useState(
    Math.min(
      Math.max((advizer?.defaultQuestionNumber || 0) - 1, 0),
      Math.max(0, (advizer?.videos.length || 0) - 1),
    ),
  );

  const { advizers } = useFilteredAdvizers(); // TODO update to have specific selectors
  const activeQuestionId = useStore(
    (state) => state.advizerSearch.activeQuestionId,
  );
  const quotes = useAdvizerQuotes(advizerId || "");

  const { nextAdvizerId, prevAdvizerId } = useMemo(() => {
    if (!advizers || advizers.length === 0) {
      return { nextAdvizerId: undefined, prevAdvizerId: undefined };
    }

    const currentIndex = advizers.findIndex(
      (a: IAdvizerModel) => a.id === advizerId,
    );
    const lastIndex = advizers.length - 1;

    return {
      nextAdvizerId:
        currentIndex === lastIndex
          ? advizers[0].id
          : advizers[currentIndex + 1]?.id,
      prevAdvizerId:
        currentIndex === 0
          ? advizers[lastIndex].id
          : advizers[currentIndex - 1]?.id,
    };
  }, [advizerId, advizers]);

  useEffect(() => {
    const advizerIsInList = !!advizers?.find(
      (a: IAdvizerModel) => a.id === advizerId,
    );

    if (!advizerIsInList && advizers && advizers.length > 0) {
      const firstAdvizerId = advizers[0].id;
      if (!firstAdvizerId) return;
      Navigation.navigate(`/advizers/${firstAdvizerId}/profile`);
    }
  }, [advizerId, advizers]);

  const renderAdvizerTags = useCallback(
    (props: { justify?: "center" | "flex-start" } = {}) => {
      return (
        <Group
          w="100%"
          gap="xs"
          px={0}
          pb="lg"
          maw={600}
          justify={props.justify || "center"}
        >
          {advizer?.tags.map((tag) => (
            <AdvizerTag key={tag.id} tag={tag} size={"lg"} />
          ))}
        </Group>
      );
    },
    [advizer?.tags],
  );

  const activeVideo = useMemo(() => {
    if (activeQuestionId) {
      return advizer?.videos.find(
        (video) => video.question?.id === activeQuestionId,
      );
    }
    return advizer?.videos[currentSlideIndex];
  }, [advizer?.videos, currentSlideIndex, activeQuestionId]);

  const activeQuote = useMemo(() => {
    if (!quotes) {
      return undefined;
    }
    return quotes.find((quote) => quote.videoId === activeVideo?.id);
  }, [quotes, activeVideo]);

  const renderAdvizerQuotes = useCallback(() => {
    if (!advizer || !activeQuote) {
      return null;
    }
    return <AdvizerQuotes advizer={advizer} activeQuote={activeQuote} />;
  }, [advizer, activeQuote]);

  const renderAdvizerInfo = () => {
    return (
      <Stack ta="left" align="start" w="100%">
        {!isLaptopOrBigger && renderAdvizerTags({ justify: "flex-start" })}
        {!isLaptopOrBigger && renderParterLogos()}
        {[
          {
            label: "Advizer Bio",
            noDiv: true,
            value: (
              <Spoiler
                maxHeight={100}
                showLabel="Show more"
                hideLabel="Hide"
                ta="left"
              >
                {/* <Text size="md" c="text.8"> */}
                {advizer?.bio}
                {/* </Text> */}
              </Spoiler>
            ),
          },

          {
            label: "Undergrad",
            value: (
              <>
                {advizer?.subjectTags.map((tag) => tag.title).join(", ")} -{" "}
                <span style={{ fontWeight: 500 }}>
                  {advizer?.undergradSchool}
                </span>
              </>
            ),
          },
          {
            label: "Graduate School",
            hide: !advizer?.gradProgramsRaw,
            value: <>{advizer?.gradProgramsRaw}</>,
          },

          {
            label: "Personal Links",
            value: (
              <>
                {parsePersonalBrandingLinks(
                  advizer?.personalBrandingLinks || "",
                )}
              </>
            ),
          },
          {
            label: "Interviewed",
            value: advizer?.interviewedTimeAgo,
          },
        ].map((item, i) => {
          if (item.hide) return null;

          return (
            <Stack w="100%" gap={0} key={item.label || i} mb="md">
              {item.label && (
                <Text c="text.9" fw="900">
                  {item.label}
                </Text>
              )}
              <Box opacity={0.8}>
                {item.label && !item.noDiv ? (
                  <Text c="text.8" fw={500}>
                    {item.value}
                  </Text>
                ) : (
                  item.value
                )}
              </Box>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  const renderAdvizerWatch = useCallback(() => {
    return (
      <Stack w="100%" gap="xl">
        {advizer?.videos
          .filter((video: IAdvizerVideo) => {
            if (activeQuestionId) {
              return video.question?.id === activeQuestionId;
            }
            return true;
          })
          .map((video: IAdvizerVideo, i: number) => {
            const matchingQuote = quotes?.find(
              (quote: IQuote) => quote.videoId === video.id,
            );

            return (
              <Card
                padding="md"
                radius="md"
                bg="transparent"
                w="100%"
                key={video.id}
              >
                <Card.Section pos="relative" h="100%" flex={1}>
                  <AdvizerVideo
                    key={i}
                    videoId={video.id}
                    video={video}
                    questionNumber={video.questionNumber}
                    title={video.title}
                    url={video.url}
                  />
                </Card.Section>
                <Card.Section>
                  <Space h="xs" />
                  <AdvizerQuotes
                    advizer={advizer}
                    activeQuote={matchingQuote}
                  />
                </Card.Section>
              </Card>
            );
          })}
      </Stack>
    );
  }, [activeQuestionId, advizer, quotes]);

  const renderParterLogos = () => {
    return (
      <Group justify="center" gap="xl">
        {(advizer?.partners || []).map((partner) => {
          return (
            <Group key={partner.id}>
              <PartnerLogo width={28} partnerId={partner.id} />
              <Text size="sm" fw={500} c="text.8">
                {partner.title}
              </Text>
            </Group>
          );
        })}
      </Group>
    );
  };

  const renderAdvizerProfileImage = () => {
    if (isTabletOrBigger) {
      return (
        <Group justify="center" align="center">
          <Button
            ariaLabel="Go to previous advizer"
            to={`/advizers/${prevAdvizerId}`}
            leftSection={<IconChevronLeft />}
            variant="transparent"
            flex={1}
            c="primary.7"
          >
            Prev Advizer
          </Button>
          <Box style={{ borderRadius: "50%" }} flex={1}>
            <AspectRatio ratio={1}>
              <Image
                src={advizer?.profilePictureURL || logoLight}
                w={90}
                mb={2}
                radius="50%"
                alt={`Advizer ${advizer?.firstName}'s profile picture`}
              />
            </AspectRatio>
          </Box>
          <Button
            ariaLabel="Go to next advizer"
            to={`/advizers/${nextAdvizerId}`}
            rightSection={<IconChevronRight />}
            variant="transparent"
            flex={1}
            c="primary.7"
          >
            Next Advizer
          </Button>
        </Group>
      );
    }
    return (
      <Box
        bg="white"
        p={0}
        bd="1px solid primary.1"
        style={{ borderRadius: "50%" }}
      >
        <AspectRatio ratio={1}>
          <Image
            src={advizer?.profilePictureURL || logoLight}
            w={90}
            mb={2}
            radius="50%"
            alt={`Advizer ${advizer?.firstName}'s profile picture`}
          />
        </AspectRatio>
      </Box>
    );
  };

  const renderVideoSlideshow = () => {
    if (!advizer) {
      return null;
    }

    return (
      <AdvizerVideoSlideshow
        advizer={advizer}
        withNavButtons
        quotes={quotes || []}
        currentSlideIndex={currentSlideIndex}
        setCurrentSlideIndex={setCurrentSlideIndex}
      />
    );
  };

  const renderAdvizerProfileToggleBtn = useCallback(() => {
    return tab === "profile" ? (
      <Button
        leftSection={<IconBulb />}
        radius="xl"
        variant="light"
        color="primary.9"
        to={`/advizers/${advizerId}/watch`}
      >
        Watch {advizer?.firstName}'s career wisdom
      </Button>
    ) : (
      <Link to={`/advizers/${advizerId}/profile`}>
        <Button
          leftSection={<IconUser />}
          radius="xl"
          variant="light"
          color="primary.9"
        >
          Learn more about {advizer?.firstName}
        </Button>
      </Link>
    );
  }, [advizer?.firstName, advizerId, tab]);

  const renderAdvizerMainInfo = () => {
    return (
      <>
        <Title order={1} c="text.9" lh={1}>
          {advizer?.firstName}
        </Title>
        <Space h="xs" />
        <Stack gap={0}>
          <Title order={2} size="h3" c="text.8">
            {advizer?.jobTitle}
          </Title>
          <Title order={3} size="h4" c="text.7">
            {advizer?.company}
          </Title>
        </Stack>
      </>
    );
  };

  const renderAdvizerProfile = () => {
    if (!advizer) {
      return (
        <Stack mih={400} align="center" justify="start" pt={200}>
          <Loader color="primary.5" size="xl" />
          <Title order={1} size="h2" c="text.7">
            Loading Advizer...
          </Title>
        </Stack>
      );
    }

    if (isLaptopOrBigger) {
      return (
        <>
          <Stack
            pl="md"
            pr="md"
            pt={80}
            pb={200}
            align={"center"}
            w="100%"
            ta={"left"}
            gap={0}
            maw={1500}
            mx="auto"
            // bg="background.0"
            h="100%"
            style={{ overflow: "auto" }}
          >
            <Space h="lg" />
            <Stack align="center" justify="center" gap={0}>
              {renderAdvizerProfileImage()}

              <Stack p="md" gap={0} ta="center">
                {renderAdvizerMainInfo()}

                {isLaptopOrBigger && (
                  <>
                    <Space h="xl" />
                    {renderAdvizerTags()}
                  </>
                )}
              </Stack>
            </Stack>
            <Space h="lg" />
            <Group w="100%" align="start" justify="center">
              <Stack align="start" justify="start" maw={700} gap="xs">
                <Stack w="100%" gap={0}>
                  {renderAdvizerQuotes()}
                </Stack>
                {renderVideoSlideshow()}
                <Space h="md" />

                {renderParterLogos()}
                <Space h={0} />
                {renderAdvizerInfo()}
              </Stack>
            </Group>
          </Stack>

          <AdvizerSearchDrawer />
        </>
      );
    }
    return (
      <Stack
        pl="md"
        pr="md"
        pt={60}
        pb={200}
        align="center"
        w="100%"
        ta="center"
        gap={0}
        // maw={600}

        h="100%"
        style={{ overflow: "auto" }}
      >
        <Stack gap={0} maw={600} mx="auto" w="100%" align="center" ta="center">
          <Space h="lg" />
          {renderAdvizerProfileImage()}
          <Space h="md" />
          {renderAdvizerMainInfo()}
          <Space h="md" />
          {renderAdvizerProfileToggleBtn()}
          <Space h="xl" />

          {tab === "profile" && <Box p="md">{renderAdvizerInfo()}</Box>}
          {tab !== "profile" && <>{renderAdvizerWatch()}</>}
        </Stack>

        <AdvizerSearchDrawer />
      </Stack>
    );
  };

  const renderMobileNavFooter = () => {
    return (
      <Group
        pos="fixed"
        bottom={-1}
        left={0}
        right={0}
        h={50}
        gap={0}
        bg="primary.9"
        style={{ zIndex: 100 }}
      >
        {[
          {
            label: "Prev",
            Icon: IconChevronLeft,
            flexDirection: "row",
            fontSize: 14,
            to: `/advizers/${prevAdvizerId}/${tab}`,
          },
          {
            Icon: IconUser,
            label: "Profile",
            active: tab === "profile",
            iconSize: 18,
            fontSize: 12,
            to: `/advizers/${advizerId}/profile`,
          },
          {
            Icon: IconVideo,
            label: "Watch",
            active: tab !== "profile",
            iconSize: 20,
            fontSize: 12,
            to: `/advizers/${advizerId}/watch`,
          },
          {
            label: "Next",
            Icon: IconChevronRight,
            flexDirection: "row-reverse",
            fontSize: 14,
            to: `/advizers/${nextAdvizerId}/${tab}`,
          },
        ].map((btn) => {
          return (
            <Link
              to={btn.to || ""}
              key={btn.label}
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="transparent"
                h="100%"
                bd={0}
                c="secondary.1"
                opacity={btn.active ? 1 : 0.5}
              >
                <Flex
                  align="center"
                  justify="center"
                  gap={0}
                  style={{
                    flexDirection: (btn.flexDirection as any) || "column",
                    paddingTop: btn.flexDirection ? undefined : 2,
                  }}
                >
                  <btn.Icon size={btn.iconSize || 24} />
                  <Text fz={btn.fontSize} opacity={0.75}>
                    {btn.label}
                  </Text>
                </Flex>
              </Button>
            </Link>
          );
        })}
      </Group>
    );
  };

  return (
    <>
      <AdvizerSearchControls />
      {renderAdvizerProfile()}
      {!isLaptopOrBigger && renderMobileNavFooter()}
    </>
  );
};
