import {
  Text as MantineText,
  TextProps as MantineTextProps,
} from "@mantine/core";
import { forwardRef, ReactNode } from "react";
import { IHeaderSize, headerSizeToFontSize } from "@/components/ui/Title";

export interface TextProps extends MantineTextProps {
  titleStyle?: IHeaderSize | boolean;
  children?: ReactNode;
}

export const Text = forwardRef<HTMLParagraphElement, TextProps>(
  (props, ref) => {
    const { titleStyle, style, children, ...rest } = props;
    return (
      <MantineText
        ref={ref}
        fw={titleStyle ? 700 : undefined}
        fz={
          titleStyle
            ? typeof titleStyle === "string"
              ? headerSizeToFontSize[titleStyle]
              : undefined
            : undefined
        }
        style={{
          ...style,
          fontFamily: titleStyle ? "League Spartan, sans-serif" : undefined,
        }}
        {...rest}
      >
        {children}
      </MantineText>
    );
  },
);

Text.displayName = "Text";
