import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { useCloseActiveDrawer } from "@/store/selectors";
// import { useMemo } from "react";

export interface LinkProps extends RouterLinkProps {
  disabled?: boolean;
  radius?: string | number;
  ariaLabel?: string;
}

export const Link = (props: LinkProps) => {
  const { to, children, disabled, onClick, radius, ariaLabel, ...rest } = props;
  const closeActiveDrawer = useCloseActiveDrawer();

  // const toWithUrlParams = useMemo(() => {
  //   const toString = typeof to === 'string' ? to : to.pathname || '';

  //   const currentUrlParams = new URLSearchParams(window.location.search);
  //   if(window.location.search);
  //   const newUrl = new URL(toString, window.location.origin);
  //   newUrl.search = currentUrlParams.toString();
  //   return newUrl.toString();
  // }, [to]);

  if (disabled) {
    return <span aria-disabled>{children}</span>;
  }

  return (
    <RouterLink
      to={to}
      aria-disabled={disabled}
      aria-label={
        typeof children === "string"
          ? children || ariaLabel
          : ariaLabel || "click link"
      }
      style={{
        pointerEvents: disabled ? "none" : undefined,
        borderRadius: radius,
      }}
      onClick={(e) => {
        closeActiveDrawer();
        onClick?.(e);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();
          closeActiveDrawer();
          onClick?.(e as any);
        }
      }}
      {...rest}
    >
      {children}
    </RouterLink>
  );
};
