import { IAdvizerModel } from "@/models/Advizer";
import { Button, Card, Group, Stack } from "@mantine/core";
import { AdvizerVideo } from "@/components/AdvizerVideo";
import { useStore } from "@/store";
import { useCallback, useMemo } from "react";
import { useAllQuestions } from "@/store/selectors";
import { IQuote } from "@/types/ApiTypes";

export const AdvizerVideoSlideshow = (props: {
  classes?: Record<string, string>;
  advizer: IAdvizerModel;
  withNavButtons?: boolean;
  quotes?: IQuote[];
  currentSlideIndex: number;
  setCurrentSlideIndex: (index: number) => void;
  children?: React.ReactNode;
}) => {
  const {
    advizer,
    withNavButtons = false,
    quotes = [],
    currentSlideIndex,
    setCurrentSlideIndex,
    children,
  } = props;

  const activeQuestionId = useStore(
    (state) => state.advizerSearch.activeQuestionId,
  );
  const allQuestions = useAllQuestions();

  const handleSlideChange = useCallback(
    (index: number) => {
      if (index > advizer.videos.length - 1) {
        index = 0;
      } else if (index < 0) {
        index = advizer.videos.length - 1;
      }
      setCurrentSlideIndex(index);
    },
    [advizer.videos.length, setCurrentSlideIndex],
  );

  const currentVideo = useMemo(() => {
    if (activeQuestionId) {
      return advizer.videos.find(
        (video: any) => video.question?.id === activeQuestionId,
      );
    }
    return advizer.videos[currentSlideIndex];
  }, [activeQuestionId, advizer.videos, currentSlideIndex]);

  return (
    <Card padding="md" radius="md" bg="transparent" w="100%" h="100%" flex={1}>
      <Card.Section pos="relative">
        <Stack w="100%" h="100%">
          {advizer.videos.map((video: any, index: number) => {
            const isCurrentVideo = currentVideo?.id === video.id;
            const matchingQuote = quotes.find(
              (quote) => quote.videoId === video.id,
            );
            return (
              <AdvizerVideo
                key={video.id}
                quote={matchingQuote}
                videoId={video.id}
                video={video}
                title={video.title || ""}
                url={video.url}
                questionNumber={video.questionNumber || index + 1}
                withControls={!activeQuestionId}
                isInBackground={!isCurrentVideo}
                onClickNext={() => {
                  handleSlideChange(index + 1);
                }}
                onClickPrevious={() => {
                  handleSlideChange(index - 1);
                }}
              />
            );
          })}
        </Stack>
      </Card.Section>
      {children && <Card.Section>{children}</Card.Section>}
      {withNavButtons && !activeQuestionId ? (
        <Card.Section>
          <Group justify="flex-start" px="xs" gap="xs" py="xs">
            {advizer.videos.map((video: any, index: number) => {
              const question = (allQuestions || [])?.find(
                (question: any) => question.id === video.questionId,
              );
              const isNotStandardQuestion = question && !question?.isStandard;

              return (
                <Button
                  key={video.id}
                  variant={currentSlideIndex === index ? "filled" : "light"}
                  color={"primary.5"}
                  size="sm"
                  onClick={() => {
                    handleSlideChange(index);
                  }}
                >
                  {isNotStandardQuestion ? "⭐" : ""} Q{video.questionNumber}
                </Button>
              );
            })}
          </Group>
        </Card.Section>
      ) : null}
    </Card>
  );
};
