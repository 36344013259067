import { forwardRef, useMemo } from "react";
import {
  Select as MantineSelect,
  SelectProps as MantineSelectProps,
} from "@mantine/core";
import { useStyles } from "@/styles/useStyles";
import classes from "@/styles/TextInput.module.css";

export interface SelectProps extends MantineSelectProps {
  bold?: boolean;
  highlight?: boolean;
}

// const shimmerDuration = 300;

// const shimmerStyles = {
//   borderImage:
//     "linear-gradient(90deg, transparent 0%, rgba(84, 255, 91, 0.4) 50%, transparent 100%) 1",
//   animation: `shimmer ${shimmerDuration}ms infinite linear`,
//   "@keyframes shimmer": {
//     "0%": {
//       borderImage:
//         "linear-gradient(90deg, transparent 0%, rgba(84, 255, 91, 0.4) 50%, transparent 100%) 1",
//       backgroundPosition: "-100% 0",
//     },
//     "100%": {
//       borderImage:
//         "linear-gradient(90deg, transparent 0%, rgba(84, 255, 91, 0.4) 50%, transparent 100%) 1",
//       backgroundPosition: "100% 0",
//     },
//   },
// };

// const transitionStyles = {
//   entering: { backgroundPosition: "-100% 0" },
//   entered: { backgroundPosition: "100% 0" },
//   exiting: { backgroundPosition: "100% 0" },
//   exited: { backgroundPosition: "-100% 0" },
// };

export const Select = forwardRef<HTMLInputElement, SelectProps>(
  (props, ref) => {
    const { className, styles, bold, label, placeholder, highlight, ...rest } =
      props;
    const { theme } = useStyles();
    const hasValue = !!props.value;

    const textColor = useMemo(() => {
      if (bold && !hasValue) {
        return theme.colors.primary[6];
      }
      return theme.colors.text[7];
    }, [bold, hasValue, theme]);

    const borderColor = useMemo(() => {
      if (bold && !hasValue) {
        return theme.colors.primary[0];
      }
      return undefined;
    }, [bold, hasValue, theme]);

    return (
      <MantineSelect
        // role="combobox"
        aria-label={String(label || placeholder)} // Add aria-label
        className={`${className}`}
        classNames={{
          input: `${classes.textInput} ${highlight ? "pulseBorder" : ""}`,
        }}
        withCheckIcon={false}
        label={label}
        placeholder={placeholder}
        styles={{
          input: {
            color: textColor,
            borderStyle: "solid",
            fontWeight: bold && !hasValue ? 600 : 500,
            borderColor: borderColor,
            borderWidth: highlight ? 2 : undefined,
            position: "relative", // Ensure proper z-index stacking
          },

          option: {
            color: theme.colors.text[8],
            fontWeight: 400,
          },
          // ...styles,
        }}
        {...rest}
        ref={ref}
      />
    );
  },
);

Select.displayName = "Select";
