import { createBrowserRouter } from "react-router-dom";
import { PageLayout } from "@/pages/PageLayout";
import { AdvizerSearchPage } from "@/pages/AdvizerSearchPage";
import { Login } from "@/pages/Login";
import { AdvizerProfile } from "@/pages/AdvizerProfilePage";
import { LogicWrapper } from "@/components/logic/LogicWrapper";
import { AuthRedirect } from "@/pages/AuthRedirect";
import { RedirectPage } from "@/components/templates/RedirectPage";
import { Page404 } from "./pages/404";

// eslint-disable-next-line react-refresh/only-export-components
const ROUTES = [
  {
    path: "/",
    element: (
      <PageLayout>
        <AdvizerSearchPage />
      </PageLayout>
    ),
  },
  {
    path: "/auth/redirect",
    element: (
      <PageLayout>
        <AuthRedirect />
      </PageLayout>
    ),
  },
  {
    path: "/p/:partnerSlug",
    element: (
      <PageLayout>
        <AdvizerSearchPage />
      </PageLayout>
    ),
  },
  {
    path: "/login",
    element: (
      <PageLayout>
        <Login />
      </PageLayout>
    ),
  },
  {
    path: "/login/general",
    element: (
      <PageLayout>
        <Login isGeneralLogin />
      </PageLayout>
    ),
  },
  {
    path: "/login/partners",
    element: (
      <PageLayout>
        <Login isPartnerSelection />
      </PageLayout>
    ),
  },
  {
    path: "/login/:partnerSlug",
    element: (
      <PageLayout>
        <Login />
      </PageLayout>
    ),
  },
  {
    path: "/advizers/:advizerId",
    element: (
      <PageLayout>
        <AdvizerProfile />
      </PageLayout>
    ),
  },
  {
    path: "/advizers/:advizerId/:tab",
    element: (
      <PageLayout>
        <AdvizerProfile />
      </PageLayout>
    ),
  },
  {
    path: "/join",
    element: <RedirectPage />,
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export const router = createBrowserRouter(
  ROUTES.map((route) => ({
    ...route,
    element: <LogicWrapper>{route.element}</LogicWrapper>,
  })),
);
