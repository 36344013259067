import { onValue, ref, update, off } from "firebase/database";
import { db } from "@/firebase/firebase";
import { useStore } from "@/store";
import { User } from "@/models/User";
import { getPartners } from "@/store/externalSelectors";

class RealtimeDatabaseService {
  get db() {
    return db;
  }

  updateUser(userId: string, data: any) {
    const userRef = ref(this.db, `users/${userId}`);
    update(userRef, data);
  }

  updateCurrentUser(data: any) {
    const activeUser = new User(useStore.getState().user?.activeUser);
    if (!activeUser) return;

    console.log("Updating current user", { activeUser, data });

    this.updateUser(activeUser.id, data);
  }

  updateCurrentUserBehaviorData(data: any) {
    const activeUser = new User(useStore.getState().user?.activeUser);
    if (!activeUser) return;

    console.log("Updating current user behavior data", { activeUser, data });

    const behaviorRef = ref(this.db, `users/${activeUser.id}/behavior`);
    update(behaviorRef, data);
  }

  subscribeToUser(userId: string, callback: (user: User) => void) {
    this.unsubscribeFromUser(userId);
    console.log("Subscribing to user", { userId });
    const userRef = ref(this.db, `users/${userId}`);
    off(userRef, "value");

    onValue(
      userRef,
      (snapshot) => {
        const userData = snapshot.val();
        console.log("User data", { userData });
        callback(userData);
      },
      (error) => {
        console.error("Error subscribing to user", error);
      },
    );
  }

  unsubscribeFromUser(userId: string) {
    const userRef = ref(this.db, `users/${userId}`);
    off(userRef, "value");
  }

  updateCurrentUserPartner(userId: string, partnerId: string) {
    const activeUser = new User(useStore.getState().user?.activeUser);
    if (!activeUser) return;

    const partner = getPartners()?.find((partner) => partner.id === partnerId);

    if (partner) {
      this.updateUser(userId, {
        partnerId,
        partnerSlug: partner?.slug || partner?.title,
      });
    }
  }
}

export const RealtimeDatabase = new RealtimeDatabaseService();
